import Flex from 'quarks/Flex';
import Link from 'quarks/Link';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

import type { FC } from 'react';

export interface BreadcrumbsProps extends BasicProps {
  breadcrumbs: {
    label: string | undefined;
    link: string;
  }[];
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs, textColor }) => (
  <Flex
    flexDirection="row"
    data-cy="breadcrumb"
    flexWrap="nowrap"
    alignItems="center"
    alignSelf="flex-start"
    gap="4px"
    className="breadcrumbs"
    css={`
      flex-wrap: wrap;
    `}
  >
    <Link
      href="/"
      textStyle="textXs"
      textDecoration="none"
      fontWeight="semiBold"
      textColor={textColor || 'gray-200'}
      paddingRight={12}
    >
      Home
    </Link>
    {breadcrumbs &&
      breadcrumbs.map(
        (item, idx) =>
          item?.label && (
            <Link key={item?.label} href={item?.link || ''} textStyle="textXs" textDecoration="none">
              <Flex flexDirection="row" alignItems="center">
                <Text paddingRight={12} fontWeight="semiBold" textColor={textColor || 'gray-400'}>
                  /
                </Text>
                <Text
                  paddingRight={12}
                  textColor={textColor ? textColor : idx === breadcrumbs.length - 1 ? 'common-white' : 'gray-200'}
                  fontWeight={idx === breadcrumbs.length - 1 ? 'regular' : 'semiBold'}
                >
                  {item?.label}
                </Text>
              </Flex>
            </Link>
          ),
      )}
  </Flex>
);

export default Breadcrumbs;
